import "./Yes.sass";
import Yesgif from "../yesgif.gif";

export default function Yes() {
  return (
    <div className="yes_toplevel">
      <div className="yes">
        <img className="img" src={Yesgif} />
        <div className="txt f3">LESSGO!</div>
      </div>
    </div>
  );
}

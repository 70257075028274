import "./App.sass";
import Home from "./Home/Home.js";
import Yes from "./Yes/Yes.js";
import No from "./No/No.js";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

function H() {
  return (
    <div>
      <Home />
    </div>
  );
}

function Y() {
  return (
    <div>
      <Yes />
    </div>
  );
}

function N() {
  return (
    <div>
      <No />
    </div>
  );
}

export default function App() {
  return (
    <div className="app_toplevel">
      <Router>
        <Routes>
          <Route path="/*" element={<Navigate to="/" />} />
          <Route exact path="/" element={<H />} />
          <Route exact path="/yes" element={<Y />} />
          <Route exact path="/no" element={<N />} />
        </Routes>
      </Router>
    </div>
  );
}

import "./No.sass";
import Nogif from "../nogif.gif";

export default function No() {
  return (
    <div className="no_toplevel">
      <div className="no">
        <img className="img" src={Nogif} />
        <div className="txt f1">I wish you good luck on your adventures!</div>
      </div>
    </div>
  );
}

import "./Home.sass";
import Homegif from "../homegif.gif";
import { Route } from "react-router-dom";
import { Link, animateScroll as scroll } from "react-scroll";

export default function Home() {
  return (
    <div className="home_toplevel">
      <div className="home">
        <img className="img" src={Homegif} />
        <div className="txt f1">
          Do you want to join forces and conquer the galaxy together?
        </div>

        <div className="home_buttons_toplevel">
          <a className="home_buttons f2" href={"yes"}>
            YOSS!
          </a>
          <a className="home_buttons f2" href={"no"}>
            No :(
          </a>
        </div>
      </div>
    </div>
  );
}
